import toSpliced from "array.prototype.tospliced";
const useMysteryBoxes = () => {
	const { t } = useT();
	const { data: appInit } = useAppInitData();
	const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
	const { data: bonusesData, refresh: refreshBonusesData } = useBonusesData();
	const { pauseAllChests } = useMysteryBoxAudios();
	const { open, close } = useAppModals();

	const activeChest = ref(0);
	const activeChestApproved = ref(false);
	const activeChestOpened = ref(false);

	const { data: chestsData, execute: fetchChestData } = useAsyncFetch({
		path: "/rest/bonus-offer/open-chests/",
		method: "post",
		options: {
			server: false,
			immediate: false,
			default: () => ({
				prizes: new Array(3).fill(undefined).map(() => ({ entries: 0, coins: 0 })),
				expireOn: "0000-00-00 00:00:00"
			}),
			transform: (data) => ({
				prizes: toSpliced(data.prizes?.slice(1), activeChest.value, 0, data.prizes?.at(0)),
				expireOn: data?.reasonValue || ""
			})
		},
		fetchOptions: {
			onResponse: () => {
				activeChestOpened.value = true;
			}
		}
	});

	const type = computed(() => bonusesData.value?.dailyChests?.reasonType || null);
	const phoneType = computed(() => !!type.value && type.value === "phone");
	const timeType = computed(() => !!type.value && type.value === "time");
	const profileType = computed(() => !!type.value && (type.value === "easy_profile" || type.value === "profile"));
	const balanceType = computed(() => !!type.value && type.value === "balance");
	const exceptTimeType = computed(() => !!type.value && type.value !== "time");

	const typeValue = computed(() => bonusesData.value?.dailyChests?.reasonValue || "");
	const profileCompleted = computed(() => appInit.value?.profileCompleted);
	const phoneConfirmed = computed(() => appInit.value?.phoneConfirmed);
	const prizesData = computed(() => chestsData.value?.prizes);

	const isMagicBoxAvailable = computed(() => bonusesData.value?.dailyChests?.available);

	const findedActiveChest = computed(() => prizesData.value?.[activeChest.value]);

	const firstTypePrize = computed(() =>
		findedActiveChest.value?.coins ? `${numberFormat(findedActiveChest.value?.coins)} ${t("FUN Coins")}` : ""
	);

	const bothTypeProzes = computed(() =>
		findedActiveChest.value?.coins && findedActiveChest.value?.entries ? t("and") : ""
	);

	const secondTypePrize = computed(() =>
		findedActiveChest.value?.entries ? `${numberFormat(findedActiveChest.value?.entries)} ${t("CITY Coins")}` : ""
	);

	const getPrizeVal = computed(() =>
		activeChestOpened.value && findedActiveChest.value
			? `${t("You won")} ${firstTypePrize.value} ${bothTypeProzes.value} ${secondTypePrize.value}!`
			: ""
	);

	const filteredChestList = computed(() =>
		prizesData.value?.filter((_: null, index: number) => index !== activeChest.value)
	);

	const isActiveSuperchargedMode = computed(() => !!bonusesData.value?.dailyChests?.isUseSuperchargedMode);
	const isSupercharged = computed(
		() => isActiveSuperchargedMode.value && !!bonusesData.value?.dailyChests?.isAvailableSuperchargedMode
	);
	const isRegular = computed(() => isActiveSuperchargedMode.value && !isSupercharged.value);
	const maxNumberOfCharge = computed(() => bonusesData.value?.dailyChests?.superchargedModeDays || 3);
	const currentCountCharge = computed(() => bonusesData.value?.dailyChests?.superchargedModeDayNumber || 0);
	const progressSupercharge = computed(
		() => Array(maxNumberOfCharge.value).fill(false).fill(true, 0, currentCountCharge.value) as boolean[]
	);

	const chooseChest = (id: number) => {
		if (!isMagicBoxAvailable.value || activeChestOpened.value) {
			return;
		}
		activeChest.value = id;
		activeChestApproved.value = true;
	};

	const handleModalClose = () => {
		refreshBonusesData();
		pauseAllChests();
	};

	const closePopup = () => {
		close("LazyOModalMysteryBox");
		handleModalClose();
	};

	const approveActiveChest = () => {
		activeChestApproved.value = true;
	};

	const confirmPhone = () => {
		open("LazyOModalPhoneConfirmation", { goToSecondStep: !!appInit.value?.phone });

		dispatchGAEvent({
			event: "click_button",
			step: "verify",
			form_name: "spin_win",
			location: "reminder"
		});
	};

	const checkBalance = () => {
		if (!isSweepStakes.value) {
			handleSwitchGameMode();
		}
		closePopup();
	};

	const openProfile = () => {
		window?.$cash?.$router?.push("/cash/account/?isOutsideCash=true");
	};

	const { duration, durationLeft, reset } = useCountdown({ timestamp: typeValue.value, formatToken: "H[:]mm[:]ss" });
	const {
		durationLeft: durationLeftExpireOn,
		duration: durationExpireOn,
		reset: resetExpireOn
	} = useCountdown({
		timestamp: chestsData.value?.expireOn,
		formatToken: "H[:]mm[:]ss"
	});

	useEvent(["SUCCESS_PHONE_CONFIRM"], () => {
		refreshBonusesData();
	});

	watch(
		() => typeValue.value,
		(value) => {
			reset(value || "");
		}
	);

	watch(
		() => chestsData.value?.expireOn,
		(value) => {
			resetExpireOn(value || "");
		}
	);

	return {
		typeValue,
		bonusesData,
		profileType,
		phoneConfirmed,
		durationLeft,
		duration,
		isMagicBoxAvailable,
		activeChest,
		activeChestOpened,
		activeChestApproved,
		phoneType,
		timeType,
		exceptTimeType,
		balanceType,
		profileCompleted,
		filteredChestList,
		getPrizeVal,
		prizesData,
		findedActiveChest,
		isSupercharged,
		progressSupercharge,
		durationLeftExpireOn,
		durationExpireOn,
		currentCountCharge,
		maxNumberOfCharge,
		isActiveSuperchargedMode,
		isRegular,
		fetchChestData,
		openProfile,
		refreshBonusesData,
		chooseChest,
		approveActiveChest,
		closePopup,
		confirmPhone,
		checkBalance,
		handleModalClose
	};
};

export default useMysteryBoxes;
